import React from "react";
import { Route, Switch } from "react-router-dom";
import { RoutesPage, RoutesAuth, RoutesUser } from "../Route/route";
import Header from "../Component/Header";
import Footer from "../Component/Footer";
import UserHeader from "../Component/UserHeader";
import NotFound from "../Component/NotFound";
import { useEffect } from "react";

const RouteWithHeader = ({ component: Component, ...rest }) => {
  const url = window.location.href.toLowerCase();
  const contains = ['!', '!#', '#', '#!'].some((char) => url.includes(char));
  const newUrl = url.replace('#', '').replace('!', '').replace('!#', '').replace('#!', '');
  useEffect(() => {
    if (window.location.search.includes('?_escaped_fragment_')) {
      const updatedSearch = window.location.search.replace('?_escaped_fragment_', '');

      const updatedURL = `${window.location.origin}${window.location.pathname}${updatedSearch}${window.location.hash}`;

      window.history.replaceState(null, null, updatedURL);
    }
    if(contains){
      window.history.replaceState(null, null, newUrl)
    }
  }, []);

  const substrings = ['mahadewi-mentoring', 'scs-wit-mentoring'];
  const result = substrings.some(substring => url?.includes(substring));
  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          {result ? null : <Header />}
          <Component {...props} title={rest.title} />
          {result ? null : <Footer />}
          
        </>
      )}
    />
  );
};

const RouteWithAuth = ({ component: Component, ...rest }) => {
  const url = window.location.href;
  const contains = ['!', '!#', '#', '#!'].some((char) => url.includes(char));
  const newUrl = url.replace('#', '').replace('!', '').replace('!#', '').replace('#!', '');
  useEffect(() => {
    if (window.location.search.includes('?_escaped_fragment_')) {
      const updatedSearch = window.location.search.replace('?_escaped_fragment_', '');

      const updatedURL = `${window.location.origin}${window.location.pathname}${updatedSearch}${window.location.hash}`;

      window.history.replaceState(null, null, updatedURL);
    }
    if(contains){
      window.history.replaceState(null, null, newUrl)
    }
  }, []);
  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          <Component {...props} title={rest.title} />
        </>
      )}
    />
  );
};

const RouteWithSidebar = ({ component: Component, ...rest }) => {
  const url = window.location.href;
  const contains = ['!', '!#', '#', '#!'].some((char) => url.includes(char));
  const newUrl = url.replace('#', '').replace('!', '').replace('!#', '').replace('#!', '');
  useEffect(() => {
    if (window.location.search.includes('?_escaped_fragment_')) {
      const updatedSearch = window.location.search.replace('?_escaped_fragment_', '');

      const updatedURL = `${window.location.origin}${window.location.pathname}${updatedSearch}${window.location.hash}`;

      window.history.replaceState(null, null, updatedURL);
    }
    if(contains){
      window.history.replaceState(null, null, newUrl)
    }
  }, []);
  return (
    <Route
      {...rest}
      render={(props) => (
        <div className="main-body">
          <div className="page-wrapper">
            <UserHeader />
            <Component {...props} title={rest.title} />
          </div>
        </div>
      )}
    />
  );
};

const Index = (props) => {
  return (
    <Switch>
      {RoutesPage.map((route, index) => (
        <RouteWithHeader
          key={index}
          exact
          path={route.path}
          component={route.component}
          title={route.title}
        />
      ))}
      {RoutesAuth.map((route, index) => (
        <RouteWithAuth
          key={index}
          exact
          path={route.path}
          component={route.component}
          title={route.title}
        />
      ))}
      {RoutesUser.map((route, index) => (
        <RouteWithSidebar
          key={index}
          exact
          path={route.path}
          component={route.component}
          title={route.title}
        />
      ))}
      <RouteWithHeader
        key={''}
        exact
        path={'*'}
        component={NotFound}
        title={'Not Found'}
      />
    </Switch>
  );
};

export default Index;
