import React, { useEffect } from "react";
import UserSidebar from "../../Component/UserSidebar";
import { Link, useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as userAction from "../../actions/userActions";
import { useForm } from "react-hook-form";
import { Row, Col, Form, Button } from "react-bootstrap";

const Index = (props) => {
  const { title, userInfo, actions } = props;
  const { register, errors, handleSubmit, watch } = useForm({
    defaultValues: userInfo,
  });
  const history = useHistory();
  useEffect(() => {
    document.title = title;
  }, [title]);

  const onSubmit = (data) => {
    data.id = userInfo.id;
    actions.updatePasswordData(data);
  };

  function SidebarAddClass() {
    document.getElementById("OnscrollBody").classList.toggle("show_sidebar");
  }

  function SidebarRemoveClass() {
    document.getElementById("OnscrollBody").classList.remove("show_sidebar");
  }

  
  const newPassword = watch('password');
  const conNewPassword = watch('password_confirmation')
  const conditionCheck = newPassword != conNewPassword && !errors.password_confirmation

  return (
    <div>
      <div className="dashboard_outer">
        <UserSidebar />
        <div className="dashboard_inner">
          <div className="d-block mb-3 mb-md-0 mb-md-none toggle_btn_outer">
            <Link
              to="#"
              onClick={SidebarAddClass}
              className="toggle_btn d-lg-none"
            >
              <i className="fas fa-bars"></i>
            </Link>
          </div>

          <h2 className="page_heading mb-3">Change Password</h2>

          <div className="box shadow bg-white p-4">
            <div className="studentformdashboard">
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col md={12}>
                    <Row className="align-items-center">
                      <Col sm={12}>
                        <Row>
                          <Col sm={12}>
                            <div className="form-group">
                              <label>Current Password*</label>
                              <Form.Control
                                type="password"
                                className={
                                  errors.current_password
                                    ? "form-control is-invalid"
                                    : "form-control"
                                }
                                name="current_password"
                                aria-invalid={errors.name ? "true" : "false"}
                                ref={register({
                                  required: true,
                                  minLength: 6,
                                  pattern: {
                                    value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!()@$%^&*-]).{6,}$/i,
                                  },
                                })}
                              />
                              {errors.current_password &&
                                errors.current_password.type === "required" && (
                                  <label className="invalid-feedback text-left">
                                    Please enter your current password
                                  </label>
                                )}
                              {errors.current_password &&
                                errors.current_password.type ===
                                  "minLength" && (
                                  <label className="invalid-feedback text-left">
                                    Your password should contain at least 6
                                    characters
                                  </label>
                                )}
                              {errors.current_password &&
                                errors.current_password.type === "pattern" && (
                                  <label className="invalid-feedback text-left">
                                   Password must have a minimum of 6 characters. It must be a combination of numerals, alphabet and special characters. Special characters don't include comma, underscores, full stop etc.
                                  </label>
                                )}
                            </div>
                          </Col>

                          <Col sm={12}>
                            <div className="form-group">
                              <label>New Password*</label>
                              <Form.Control
                                type="password"
                                className={
                                  errors.password
                                    ? "form-control is-invalid"
                                    : "form-control"
                                }
                                name="password"
                                aria-invalid={errors.name ? "true" : "false"}
                                ref={register({
                                  required: true,
                                  minLength: 6,
                                  pattern: {
                                    value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!()@$%^&*-]).{6,}$/i,
                                  },
                                })}
                              />
                              {errors.password &&
                                errors.password.type === "required" && (
                                  <label className="invalid-feedback text-left">
                                    Please enter your password
                                  </label>
                                )}
                              {errors.password &&
                                errors.password.type === "minLength" && (
                                  <label className="invalid-feedback text-left">
                                    Your password should contain at least 6
                                    characters
                                  </label>
                                )}
                              {errors.password &&
                                errors.password.type === "pattern" && (
                                  <label className="invalid-feedback text-left">
                                    Your password should contain at-least 1
                                    Uppercase, 1 Lowercase, 1 Numeric and 1
                                    special character
                                  </label>
                                )}
                            </div>
                          </Col>
                          <Col sm={12}>
                            <div className="form-group">
                              <label>Confirm Password*</label>
                              <Form.Control
                                type="password"
                                className={
                                  errors.password_confirmation
                                    ? "form-control is-invalid"
                                    : "form-control"
                                }
                                name="password_confirmation"
                                aria-invalid={
                                  errors.password_confirmation
                                    ? "true"
                                    : "false"
                                }
                                ref={register({
                                  required: true,
                                  minLength: 6,
                                  maxLength: 15,
                                  pattern: {
                                    value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!()@$%^&*-]).{6,}$/i,
                                  },
                                })}
                              />
                              {errors.password_confirmation &&
                                errors.password_confirmation.type ===
                                  "required" && (
                                  <label className="invalid-feedback text-left">
                                    Please enter your confirmation password
                                  </label>
                                )}
                              {errors.password_confirmation &&
                                errors.password_confirmation.type ===
                                  "minLength" && (
                                  <label className="invalid-feedback text-left">
                                    Your password should contain at least 6
                                    characters
                                  </label>
                                )}
                              {errors.password_confirmation &&
                                errors.password_confirmation.type ===
                                  "pattern" && (
                                  <label className="invalid-feedback text-left">
                                    Your password should contain at-least 1
                                    Uppercase, 1 Lowercase, 1 Numeric and 1
                                    special character
                                  </label>
                                )}
                                {conditionCheck ?  <label className="text-left" style={{color:'#dc3545', fontSize:'0.875em', marginTop:'0.25rem'}}>
                              New password and confirm password does not match
                            </label> : null}
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>

                  <Col sm={12}>
                    <Row>
                      <Col md={12}>
                        <div className="form-group mt-3 text-left">
                          <Button
                            className="btn loginsubmit nextbtn ml-2"
                            type="submit"
                          >
                            Submit
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </div>
      </div>

      <div className="sidebar_overlay" onClick={SidebarRemoveClass}></div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    userInfo: state.userInfo,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(userAction), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
