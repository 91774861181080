import React, { useEffect, useState, useCallback } from "react";
import { useLocation, useHistory, Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as menteeDashboardAction from "../../actions/menteeDashboardAction";
import { Container, Row, Col } from "react-bootstrap";
import { agent } from "../../utils/agent";
import * as API from "../../utils/apiPath";
import UserSidebar from "../../Component/UserSidebar";
import Details from "./details";
import ReactPaginate from "react-paginate";
import { showToaster, showToasterWithOk } from "../../utils/helpers";
import { Tooltip } from "@material-ui/core";
import config from "../../config";
const queryString = require("query-string");

const Index = (props) => {
  const history = useHistory();
  const location = useLocation();

  const { actions, mentorsData, userInfo, isAuth, title } = props;

  const [count, setCount] = useState(0);
  const [choosenMentors, setChoosenMentors] = useState([]);
  const [userCurrentRelationship, setUserCurrentRelationship] = useState("");
  const getRequestCount = useCallback(() => {
    const userId = userInfo.id ? userInfo.id : "";
    if (userId != '') {
      agent
        .get(API.GETSELECTEDMENTORCOUNT + "/" + userInfo.id)
        .then((res) => {
          setCount(res.data.data);
        })
        .catch((err) => {
          showToaster(err.message);
        });
    }
  }, [userInfo]);

  const getSelectedMentors = () => {
    const userId = userInfo.id ? userInfo.id : "";
    const request = {};
    request.userId = userId;
    agent
      .post(API.GETCHOOSENMENTORS, request)
      .then((res) => {
        setChoosenMentors(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getMentor = (e, mentorId) => {
    e.preventDefault();
    const userId = userInfo.id;
    const request = {};
    request.userId = userId;
    request.mentorId = mentorId;
    if (count >= 5) {
      showToaster(
        "You can request for upto 5 preferred mentors only. We will try our best to assign a mentor of your choice considering preferred domains, mentors' availability and specified objectivs."
      );
    } else {
      actions.saveSelectMentor(request);
    }
    //actions.saveSelectMentor(request);
    //actions.updateUserData();
  };

  const setMentorWaitlistAvailability = (e, mentorId) => {
    e.preventDefault();
    const userId = userInfo.id;
    const request = {};
    request.userId = userId;
    request.mentorId = mentorId;
    actions.saveWaitlistMentor(request);
  };

  // const getWaitlistMentor = async (
  //   e,
  //   mentorId,
  //   avail_start_time,
  //   avail_end_time
  // ) => {
  //   e.preventDefault();
  //   /* Get relationship with mentee */
  //   agent
  //     .get(
  //       API.LOAD_CUSTOMER +
  //         "/getUserCurrentRelationship/" +
  //         userInfo.id +
  //         "/" +
  //         mentorId
  //     )
  //     .then((res) => {
  //       setUserCurrentRelationship(res.data.data);
  //       //return res.data.data;
  //     })
  //     .catch((err) => {
  //       throw err;
  //     });

  //   //return false;
  //   var today = new Date();
  //   var dateCheck =
  //     today.getFullYear() +
  //     "-" +
  //     (today.getMonth() + 1) +
  //     "-" +
  //     today.getDate();

  //   var d1 = avail_start_time.split("-");
  //   var d2 = avail_end_time.split("-");
  //   var c = dateCheck.split("-");

  //   var from = new Date(d1[2], parseInt(d1[1]) - 1, d1[0]); // -1 because months are from 0 to 11
  //   var to = new Date(d2[2], parseInt(d2[1]) - 1, d2[0]);
  //   var check = new Date(c[2], parseInt(c[1]) - 1, c[0]);

  //   e.preventDefault();
  //   if (isAuth === false) {
  //     showToasterWithOk(
  //       "Thank you for browsing vLookUp! Help us create a meaningful mentoring engagement for you by registering in one simple step, or login if you already have an account."
  //     );
  //     history.push(`/register`);
  //   } else if (check > from && check < to) {
  //     showToasterWithOk(
  //       "You have already been assigned a mentor. You can select another mentor after the relationship is complete."
  //     );
  //   } else {
  //     const userId = userInfo.id;
  //     const request = {};
  //     request.userId = userId;
  //     request.mentorId = mentorId;
  //     //actions.saveSelectMentor(request);
  //     if (count >= 5) {
  //       showToaster(
  //         "You can request for upto 5 preferred mentors only. We will try our best to assign a mentor of your choice considering preferred domains, mentors' availability and specified objectivs."
  //       );
  //     } else {
  //       actions.saveSelectMentor(request);
  //     }
  //     actions.updateUserData();
  //   }
  // };

  const getUserDetails = (
    e,
    item
  ) => {
    e.preventDefault();
    setModalShow(true);
    setUserData(item);
  };

  useEffect(() => {
    getSelectedMentors();
  }, []);

  useEffect(() => {
    const request = { page_type: "suggested_mentors" };
    getRequestCount();
    document.title = "Suggested Mentors";
    const params = new URLSearchParams(location.search);
    const queryStringParsed = queryString.parse(location.search);
    if (isAuth) {
      request.experience_levels = userInfo?.experience_levels[0]?.id
    }
    if (params.get("page")) {
      var page = queryStringParsed["page"];
      request.page = page;
    }
    if (params.get("mf_mental_health")) {
      var mf_mental_health = queryStringParsed["mf_mental_health"];
      setMentalHealth(mf_mental_health);
      request.mf_mental_health = mf_mental_health;
    }
    if (params.get("lgbtq_discussion")) {
      var lgbtq_discussion = queryStringParsed["lgbtq_discussion"];
      // setMentalHealth(lgbtq_discussion);
      request.lgbtq_discussion = lgbtq_discussion;
    }
    if (params.get("mf_certified_coach")) {
      var mf_certified_coach = queryStringParsed["mf_certified_coach"];
      setCertifiedCoach(mf_certified_coach);
      request.mf_certified_coach = mf_certified_coach;
    }

    actions.loadData(
      request,
      userInfo.id,
      "Mentor",
      userInfo.domains,
      userInfo.programmes,
    );
  }, [actions, userInfo, getRequestCount, location]);

  function SidebarAddClass(e) {
    e.preventDefault();
    document.getElementById("OnscrollBody").classList.toggle("show_sidebar");
  }

  function SidebarRemoveClass() {
    document.getElementById("OnscrollBody").classList.remove("show_sidebar");
  }

  const [modalShow, setModalShow] = useState(false);
  const [userData, setUserData] = useState({});
  const [viewpage, setViewPage] = useState(0);

  const handlePageClick = (data) => {
    setViewPage(data.selected);
    let page = data.selected + 1;
    const params = new URLSearchParams(location.search);
    params.set("page", page);
    history.push({
      pathname: "/dashboard",
      search: "?" + params,
    });
    window.scrollTo(0, 0);
  };
  // console.log("mentorsData--->", mentorsData);
  // console.log("choosenMentors--->", choosenMentors);
  const [mentalHealth, setMentalHealth] = useState("");
  const [certifiedCoach, setCertifiedCoach] = useState("");
  const params = new URLSearchParams(location.search);
  const setSelectedHealth = (e, type) => {
    const val = e.target.checked;

    if (type == "mf_mental_health") {
      if (val == true) {
        params.set("mf_mental_health", 1);
        setMentalHealth(1);
      } else {
        params.set("mf_mental_health", "");
        setMentalHealth("");
      }
    }
    if (type == "mf_certified_coach") {
      if (val == true) {
        params.set("mf_certified_coach", 1);
        setCertifiedCoach(1);
      } else {
        params.set("mf_certified_coach", "");
        setCertifiedCoach("");
      }
    }
    // Set parameter for searching data
    if (params.get("page") > 1) {
      params.set("page", 1);
    }
    history.push({
      pathname: `/dashboard`,
      search: "?" + params,
    });
  };
  return (
    <div>
      <div className="dashboard_outer">
        <UserSidebar />

        <div className="dashboard_inner">
          <div className="d-block mb-3 mb-md-0 mb-md-none toggle_btn_outer">
            <Link
              to=""
              onClick={SidebarAddClass}
              className="toggle_btn d-lg-none"
            >
              <i className="fas fa-bars"></i>
            </Link>
          </div>
          <h1 className="dash_heading">Suggested Mentors</h1>
          <div className="seprator health_check">
            {/* <h6>Select Organisation</h6> */}
            <ul className="d-flex align-items-center mr-2 mb-3">
              <li className="mr-3 text-dark">Filters</li>
              <li className="mr-3 mr-md-5">
                <span className="custom_check">
                  &nbsp;{" "}
                  <input
                    value={mentalHealth ? 1 : 0}
                    type="checkbox"
                    onClick={(e) => setSelectedHealth(e, "mf_mental_health")}
                    defaultChecked={
                      params.get("mf_mental_health") || mentalHealth
                        ? true
                        : false
                    }
                  ></input>
                  <span className="check_indicator">&nbsp;</span>
                </span>
                <Tooltip
                  size="4xl"
                  title={<h5>{config?.mf_mental_health}</h5>}
                  describeChild
                  arrow
                >
                  <img
                    src={
                      process.env.REACT_APP_STATIC_IMAGE_PATH + "usermind.svg"
                    }
                  />
                </Tooltip>{" "}
              </li>
              <li>
                <span className="custom_check">
                  &nbsp;{" "}
                  <input
                    type="checkbox"
                    value={certifiedCoach ? true : false}
                    onClick={(e) => setSelectedHealth(e, "mf_certified_coach")}
                    defaultChecked={
                      params.get("mf_certified_coach") || certifiedCoach
                        ? true
                        : false
                    }
                  ></input>
                  <span className="check_indicator">&nbsp;</span>
                </span>
                <Tooltip
                  size="4xl"
                  title={<h5>{config?.mf_certified_coach}</h5>}
                  describeChild
                  arrow
                >
                  <img
                    src={
                      process.env.REACT_APP_STATIC_IMAGE_PATH + "useraward.svg"
                    }
                  />
                </Tooltip>{" "}
              </li>
            </ul>
          </div>
          <div className="box_wrap shadow-sm bg-white mentor_box">
            <h3 className="">
              Here is a list of fab mentors based on your selected domains & objectives. Please indicate your preference for up to 5 mentors. We will try our best to assign a mentor of your choice.
            </h3>

            
            {userInfo?.programmes == 20 && <h3 className="text-dark">
              <Link to={{
                pathname: '/ignite-women-mentoring',
                state: 'ignite-women-mentoring',
              }} target="_blank" >
               Browse through the complete list of IGNITE Mentors.
              </Link>
            </h3>}
            
            {userInfo?.programmes == 22 && <h3 className="text-dark">
              <Link to={{
                pathname: '/mahadewi-mentoring',
                state: 'mahadewi-mentoring',
              }} target="_blank" >
                Browse through the complete list of DOID Mentors.
              </Link>
            </h3>}
            {userInfo?.programmes == 25 && <h3 className="text-dark">
              <Link to={{
                pathname: '/scs-wit-mentoring',
                state: 'scs-wit-mentoring',
              }} target="_blank" >
                Browse through the complete list of SCS WIT Mentors.
              </Link>
            </h3>}
            <div className="mentor_list dash_MentorList">
              {mentorsData &&
                mentorsData.map((item, key) => (
                  <>
                    {/* {choosenMentors.indexOf(item.id) <= -1 && */}
                    {/* item.is_relationship === 0 && ( */}
                    <div className="menteesCols" key={key}>
                      <div className="outer position-relative">
                        <div className="mentor_img">
                          {item?.is_reached_limit == 0 ? (item?.user_availability_check?.availability == 1 ? <span className="AvailabilityStatus m-3">Available</span> : <span className="UnavailabilityStatus m-3">Unavailable</span>) : <span className="UnavailabilityStatus m-3">Unavailable</span>}
                          {item.image && (
                            <img
                              src={item.image}
                              alt="career mentoring program"
                            />
                          )}
                          {!item.image && (
                            <img
                              src={
                                process.env.REACT_APP_STATIC_IMAGE_PATH +
                                "mentor_women_two.png"
                              }
                              alt="best career mentoring program"
                            />
                          )}
                        </div>
                        <div className="mentor_info">
                          <div className="">
                            <span>
                              {item.country_image_path && (
                                <img src={item.country_image_path} alt="professional consultants for women" className="country_flag" />
                              )}
                            </span>
                            <h3>{item.full_name}</h3>
                          </div>
                          <h6>{item.position}</h6>
                          <p>
                            {item.organization !== "undefined" ||
                              item.organization === ""
                              ? item.organization
                              : "N/A"}
                          </p>
                          <p>
                            {item.main_area !== "undefined" ||
                              item.main_area === ""
                              ? item.main_area
                              : "N/A"}
                          </p>
                        </div>
                        <div className="mentor_btn">
                          <div>
                            {userInfo.role_id !== "Mentor" &&
                              item.is_relationship === 0 &&
                              item.is_mentor_terminate === 0 &&
                              item.is_same_program === 1 && (
                                <>
                                  {item.is_reached_limit === 0 &&
                                    item.user_availability === 1 ? (
                                    <Link
                                      to=""
                                      onClick={(e) => {
                                        getMentor(
                                          e,
                                          item.id,
                                          item.avail_start_time,
                                          item.avail_end_time
                                        );
                                      }}
                                    >
                                      Indicate as Preferred Mentor
                                    </Link>
                                  ) : (
                                    <Link
                                      to=""
                                      onClick={(e) => {
                                        setMentorWaitlistAvailability(
                                          e,
                                          item.id
                                        );
                                      }}
                                    >
                                      Waitlist for availability
                                    </Link>
                                  )}
                                </>
                              )}
                            <Link
                              to=""
                              id={key}
                              onClick={(e) => {
                                getUserDetails(
                                  e,
                                  item
                                );
                              }}
                            >
                              {"Read More"}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* ) */}
                    {/* } */}
                  </>
                ))}
            </div>
            {props.pagination.total_pages && (
              <ReactPaginate
                previousLabel={"previous"}
                nextLabel={"next"}
                forcePage={viewpage}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={props.pagination.total_pages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={props.pagination.per_page}
                onPageChange={(v) => handlePageClick(v)}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            )}
          </div>
        </div>
      </div>

      <div className="sidebar_overlay" onClick={SidebarRemoveClass}></div>

      {modalShow && (
        <Details
          show={modalShow}
          userdata={userData}
          onHide={() => setModalShow(false)}
          centered
          size="xl"
          className="mentor_modal"
        />
      )}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    mentorsData: state.mentorsData,
    userInfo: state.userInfo,
    pagination: state.pagination,
    isAuth: state.isAuth
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(menteeDashboardAction), dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
