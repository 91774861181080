import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import UserHeader from "../../Component/UserHeader";
import UserSidebar from "../../Component/UserSidebar";
import { LOAD_DOMAINS_LIST } from "../../utils/apiPath";
import { agent } from "../../utils/agent";
import { useForm } from "react-hook-form";
import * as userAction from "../../actions/userActions";
import * as baseActions from "../../actions/baseActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { showToaster } from "../../utils/helpers";
import { Row, Col, Form, Button } from "react-bootstrap";

import { LOAD_AREAS_LIST } from "../../utils/apiPath";

const UpdateProfile3 = (props) => {
  const { title, actions, updateProfileData, userInfo } = props;
  const { register, errors, handleSubmit } = useForm();
  // const [domains, setDomains] = useState([]);
  const [getLoader, setLoader] = useState(false);

  // Fetching domain list
  // const getData = (page = 1) => {
  //   agent
  //     .get(LOAD_DOMAINS_LIST, {})
  //     .then((res) => {
  //       setDomains(res.data.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const [areas, setAreas] = useState([]);
  const getAreasData = (page = 1) => {
    agent
      .get(LOAD_AREAS_LIST, {})
      .then((res) => {
        setAreas(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const [selectedMainAreaObj, setSelectedMainArea] = useState([]);
  // const [selectedSubAreaObj, setSelectedSubArea] = useState([]);

  useEffect(() => {
    if (updateProfileData) {
      if (updateProfileData.domains) {
        const tempMain = updateProfileData.domains.split(",");
        console.log("TEMP-----", tempMain);
        setMainArea(tempMain);
        // setSelectedMainArea(updateProfileData.domains.split(","));
        // setMainArea(updateProfileData.domains.split(","));
      }
      if (updateProfileData.subareas) {
        const tempSub = updateProfileData.subareas.split(",");
        setSelectedArea(tempSub);
        // setSelectedSubArea(updateProfileData.subareas.split(","));
        // setSelectedArea(updateProfileData.subareas.split(","));
      }
      if(updateProfileData?.image){
        localStorage.setItem('profileImage', updateProfileData?.image)
      }
    }
  }, [updateProfileData]);

  function SidebarAddClass() {
    document.getElementById("OnscrollBody").classList.toggle("show_sidebar");
  }

  function SidebarRemoveClass() {
    document.getElementById("OnscrollBody").classList.remove("show_sidebar");
  }

  const [selectedArea, setSelectedArea] = useState([]);
  const [mainArea, setMainArea] = useState([]);
  const setSelectedMainAreaArray = (e, catId) => {
    if (
      document.querySelectorAll(".mainArea" + catId + ":checked").length > 0
    ) {
      document.getElementById("mainArea" + catId).checked = true;
      document
        .getElementById("mainArea" + catId)
        .classList.remove("is-invalid");
    } else {
      document.getElementById("mainArea" + catId).checked = false;
      document.getElementById("mainArea" + catId).classList.add("is-invalid");
    }
    // document.getElementById("mainArea" + catId).checked = true;
    const val = e.target.value;
    let cloneSelected = [...selectedArea];
    if (selectedArea.find((item) => item === val)) {
      cloneSelected = selectedArea.filter((item) => item !== val);
    } else {
      cloneSelected.push(val);
    }
    setSelectedArea(cloneSelected);

    /* SetMainArea keys */
    let cloneMainSelected = [...mainArea];
    if (
      document.querySelectorAll(".mainArea" + catId + ":checked").length == 0
    ) {
      cloneMainSelected = cloneMainSelected.filter((item) => item != catId);
    } else {
      var newValue = catId.toString();
      if (!cloneMainSelected.includes(newValue))
        cloneMainSelected.push(newValue);
    }
    setMainArea(cloneMainSelected);
    /* SetMainArea keys */
  };
  // const setSelectedMainCategoryArray = (e) => {
  //   const val = e.target.value;
  //   let cloneMainSelected = [...mainArea];
  //   if (mainArea.find((item) => item === val)) {
  //     cloneMainSelected = mainArea.filter((item) => item !== val);
  //   } else {
  //     cloneMainSelected.push(val);
  //   }
  //   setMainArea(cloneMainSelected);
  // };
  const setSelectedMainCategoryArray = (e, mainAreaId) => {
    const val = e.target.value;
    const temp = [...areas];
    const mainArr = [...mainArea];
    const arr = [...selectedArea];
    const catChecked = document.getElementById("mainArea" + mainAreaId).checked;
    if (catChecked) {
      mainArr.push(val);
      setMainArea(mainArr);
    } else {
      var filteredArray = mainArr.filter((dta) => dta !== val);
      setMainArea(filteredArray);
    }

    if (catChecked) {
      temp.map((data) => {
        if (val == data.id) {
          return data.subcategory.map((item) => {
            arr.push(item.id.toString());
          });
        }
      });
      setSelectedArea(arr);
    } else {
      temp.map((data) => {
        if (val == data.id) {
          let c = data.subcategory.map((i) => {
            return i.id.toString();
          });
          const RESULT = arr.filter((i) => {
            return c.indexOf(i) == -1;
          });
          setSelectedArea(RESULT);
        }
      });
    }

    // PREVIOUS CODE START
    // let cloneMainSelected = [...mainArea];
    // if (mainArea.find((item) => item === val)) {
    //   cloneMainSelected = mainArea.filter((item) => item !== val);
    // } else {
    //   cloneMainSelected.push(val);
    // }
    // setMainArea(cloneMainSelected);
    // PREVIOUS CODE STOP
  };
  const history = useHistory();

  useEffect(() => {
    document.title = title;
    // getData();
    getAreasData();
  }, [title, actions, userInfo]);

  const onSubmit = (data) => {
    setLoader(true);
    const resultArr = mainArea.filter((data1, index) => {
      return mainArea.indexOf(data1) === index;
    });
    if (resultArr.length > 3) {
      showToaster(
        "Please select only up to 3 areas of interest for us to create a more meaningful connection for you."
      );
      setLoader(false);
      return false;
    }
    data.id = updateProfileData.id;
    data.domains = resultArr;
    data.subareas = selectedArea;
    actions.updateStep3(data, userInfo.id);
    //history.push(`/manage-profile-step4`);
  };

  const clearAll = () => {
    setMainArea([])
    setSelectedArea([])
  };

  // console.log("MAIN AREA", mainArea);
  // console.log("SELECTED AREA", selectedArea );

  return (
    <div>
      <UserHeader />

      <div className="dashboard_outer">
        <UserSidebar />

        <div className="dashboard_inner">
          <div className="d-block mb-3 mb-md-0 mb-md-none toggle_btn_outer">
            <Link
              to="#"
              onClick={SidebarAddClass}
              className="toggle_btn d-lg-none"
            >
              <i className="fas fa-bars"></i>
            </Link>
          </div>

          <h2 className="page_heading mb-3">Edit Profile</h2>

          <div className="box shadow bg-white p-md-5">
            <div className="studentformdashboard pt-md-4">
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Col md={12}>
                  <Row className="">
                    <Col sm={10}>
                      <Row>
                        <Col sm={12} className="">
                          {updateProfileData.role_id === "Mentor" && (
                            <h4 className="">
                              {" "}
                              Please keep your primary and sub-domain updated as
                              we use this as a primary criteria to curate your
                              connection with mentees.Please select upto 3 areas
                              only for effective matching process.
                            </h4>
                          )}

                          {updateProfileData.role_id === "Mentees" && (
                            <h4 className="">
                              Please keep your primary and sub-domain updated as
                              we use this as a primary criteria to curate your
                              connection with mentors. Please select upto 3
                              areas only for effective matching process.
                            </h4>
                          )}
                          <div className="businessboxouter">
                            {areas &&
                              areas.map((areaData, key) => {
                                let DOMAIN = mainArea.some(
                                  (item) => item == areaData.id
                                );
                                return (
                                  <div key={key}>
                                    <h5>
                                      <input
                                        type="checkbox"
                                        name="mainArea"
                                        value={areaData.id}
                                        id={`mainArea${areaData.id}`}
                                        className={`form-check-input ${
                                          errors.mainArea ? "is-invalid" : ""
                                        }`}
                                        // defaultChecked={
                                        //   selectedMainAreaObj.includes(
                                        //     areaData.id.toString()
                                        //   )
                                        //     ? true
                                        //     : false
                                        // }
                                        checked={DOMAIN == true ? 1 : 0}
                                        onChange={(e) =>
                                          setSelectedMainCategoryArray(
                                            e,
                                            areaData?.id
                                          )
                                        }
                                        ref={register({
                                          required: true,
                                        })}
                                      />
                                      {errors.mainArea &&
                                        key === 0 &&
                                        errors.mainArea.type === "required" && (
                                          <label className="invalid-feedback text-left">
                                            Please choose at least one main area
                                          </label>
                                        )}
                                      <div className="selectboxouter">
                                        {areaData.name}
                                      </div>
                                    </h5>

                                    <ul className="bussinessSelect">
                                      {areaData &&
                                        areaData.subcategory.map(
                                          (item, key) => {
                                            let SUB_AREA = selectedArea.some(
                                              (dta) => dta == item.id
                                            );
                                            return (
                                              <li key={key}>
                                                <input
                                                  type="checkbox"
                                                  name="subarea"
                                                  value={item.id}
                                                  // defaultChecked={
                                                  //   selectedSubAreaObj.includes(
                                                  //     item.id.toString()
                                                  //   )
                                                  //     ? true
                                                  //     : false
                                                  // }
                                                  checked={
                                                    SUB_AREA == true ? 1 : 0
                                                  }
                                                  className={`form-check-input mainArea${
                                                    areaData.id
                                                  }  ${
                                                    errors.subarea
                                                      ? "is-invalid"
                                                      : ""
                                                  }`}
                                                  onChange={(e) =>
                                                    setSelectedMainAreaArray(
                                                      e,
                                                      areaData.id
                                                    )
                                                  }
                                                  ref={register}
                                                />

                                                <div className="selectboxouter">
                                                  {item.name}
                                                </div>
                                              </li>
                                            );
                                          }
                                        )}
                                    </ul>
                                  </div>
                                );
                              })}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col sm={12} className="">
                  <Row>
                    <Col md={12}>
                      <div className="form-group mt-3 text-left">

                      <Button
                          className="btn loginsubmit outline ml-2 mr-2 clearallbtn" 
                          onClick={clearAll}
                        >
                          Clear All
                        </Button>

                        <Button
                          className="btn loginsubmit nextbtn outline mr-2" 
                          onClick={(e) => {
                            e.preventDefault();
                            history.push("/manage-profile");
                          }}
                        >
                          Previous
                        </Button>

                        <Button
                          className="btn loginsubmit nextbtn ml-2 mr-2"
                          type="submit"
                          disabled={getLoader}
                        >
                          {getLoader ? " Saving... " : "Save & Next"}
                        </Button>

                        {/* <Button
                        className="btn loginsubmit nextbtn outline ml-2"
                        onClick={(e) => {
                        e.preventDefault();
                        history.push("/manage-profile-step4");
                        }}
                    >
                        Next
                    </Button> */}
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Form>
            </div>
          </div>
        </div>
      </div>

      <div className="sidebar_overlay" onClick={SidebarRemoveClass}></div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    isData: state.isData,
    updateProfileData: state.updateProfileData,
    isLoad: state.isLoad,
    userInfo: state.userInfo,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(userAction, baseActions),
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateProfile3);
