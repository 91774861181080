import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap";
import { LOAD_DOMAINS_LIST, LOAD_AREAS_LIST } from "../../utils/apiPath";
import { agent } from "../../utils/agent";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import * as API from "../../utils/apiPath";
import { LOAD_ORGANIZATION_LIST } from "../../utils/apiPath";
import { showToaster } from "../../utils/helpers";
const StudentSignup2 = (props) => {
  // const [domains, setDomains] = useState([]);
  const [areas, setAreas] = useState([]);
  const { title } = props;
  const { register, errors, handleSubmit } = useForm();

  const history = useHistory();
  const [selectedArea, setSelectedArea] = useState([]);
  const [mainArea, setMainArea] = useState([]);
  const [selectedMainAreaObj, setSelectedMainArea] = useState([]);
  // Fetching domain list
  // const getData = (page = 1) => {
  //   agent
  //     .get(LOAD_DOMAINS_LIST, {})
  //     .then((res) => {
  //       setDomains(res.data.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const getAreasData = (page = 1) => {
    agent
      .get(LOAD_AREAS_LIST, {})
      .then((res) => {
        setAreas(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getUserId = localStorage.getItem("userId");
  useEffect(() => {
    document.title = title;
    const getSelectedMainAreaData = (page = 1) => {
      agent
        .get(API.GETUSERDATA + "/" + getUserId)
        .then((res) => {
          if (res.data.data.domains) {
            // console.log("res.data.data.domains.split(", ")", res.data.data.subareas);
            // setSelectedMainAreaArray(84, 43);
            const tempMain = res.data.data.domains.split(",");
            const tempSub = res.data.data.subareas.split(",");
            setMainArea(tempMain);
            setSelectedArea(tempSub);
            // setSelectedMainArea(res.data.data.domains.split(","));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getSelectedMainAreaData();
    getAreasData();
    // getData();
    getAreasData();
  }, [title, getUserId]);

  const setSelectedMainAreaArray = (e, catId) => {
    if (
      document.querySelectorAll(".mainArea" + catId + ":checked").length > 0
    ) {
      document.getElementById("mainArea" + catId).checked = true;
      document
        .getElementById("mainArea" + catId)
        .classList.remove("is-invalid");
    } else {
      document.getElementById("mainArea" + catId).checked = false;
      document.getElementById("mainArea" + catId).classList.add("is-invalid");
    }
    const val = e.target.value;
    let cloneSelected = [...selectedArea];
    if (selectedArea.find((item) => item === val)) {
      cloneSelected = selectedArea.filter((item) => item !== val);
    } else {
      cloneSelected.push(val);
    }
    console.log("cloneSelected--->", cloneSelected);
    setSelectedArea(cloneSelected);

    /* SetMainArea keys */
    let cloneMainSelected = [...mainArea];
    if (
      document.querySelectorAll(".mainArea" + catId + ":checked").length == 0
    ) {
      cloneMainSelected = cloneMainSelected.filter((item) => item != catId);
    } else {
      var newValue = catId.toString();
      if (!cloneMainSelected.includes(newValue))
        cloneMainSelected.push(newValue);
    }
    setMainArea(cloneMainSelected);
    /* SetMainArea keys */
  };
  const setSelectedMainCategoryArray = (e, mainAreaId) => {
    const val = e.target.value;
    const temp = [...areas];
    const mainArr = [...mainArea];
    const arr = [...selectedArea];
    const catChecked = document.getElementById("mainArea" + mainAreaId).checked;
    if (catChecked) {
      mainArr.push(val);
      setMainArea(mainArr);
    } else {
      var filteredArray = mainArr.filter((dta) => dta !== val);
      setMainArea(filteredArray);
    }

    if (catChecked) {
      temp.map((data) => {
        if (val == data.id) {
          return data.subcategory.map((item) => {
            arr.push(item.id.toString());
          });
        }
      });
      setSelectedArea(arr);
    } else {
      temp.map((data) => {
        if (val == data.id) {
          let c = data.subcategory.map((i) => {
            return i.id.toString();
          });
          const RESULT = arr.filter((i) => {
            return c.indexOf(i) == -1;
          });
          setSelectedArea(RESULT);
        }
      });
    }
    // PREVIOUS CODE START
    // let cloneMainSelected = [...mainArea];
    // if (mainArea.find((item) => item === val)) {
    //   cloneMainSelected = mainArea.filter((item) => item !== val);
    // } else {
    //   cloneMainSelected.push(val);
    // }
    // setMainArea(cloneMainSelected);
    // PREVIOUS CODE STOP
  };

  const onSubmit = async (data) => {
    const resultArr = mainArea.filter((data1, index) => {
      return mainArea.indexOf(data1) === index;
    });
    if (resultArr.length > 3) {
      showToaster(
        "Please select only up to 3 areas of interest for us to create a more meaningful connection for you."
      );
      return false;
    }

    data.userId = localStorage.getItem("userId");
    data.stepNumber = 2;
    data.subareas = selectedArea;
    data.domains = resultArr;
    data.roleId = "Mentees";
    const response = await agent.post(API.SAVEUSERDETAILS, data);
    // console.log(response)
    if (response?.data?.status === 200) {
      history.push(`/mentee-signup-step-3`);
    } else {
      showToaster(response?.data?.message);
    }
  };

  const clearAll = () => {
    setMainArea([])
    setSelectedArea([])
  };
  return (
    <div>
      <section className="loginouter studentsteps">
        <Container>
          <Form onSubmit={handleSubmit(onSubmit)} className="mt-3">
            <Row className="align-items-center ">
              <Col md={12}>
                <div className="logininnerouter">
                  <div className="w-100">
                    <div className="loginlogo text-center mb-5 pb-4">
                      <Link to="/">
                        <img src="/assets/images/logo.svg" alt="logo" />
                      </Link>
                    </div>
                    <div className="loginform">
                      <h1>Tell us about yourself</h1>
                      <div className="steps_signup">
                        <div>
                          <span className="stepcount active">1</span>
                          <span className="stepcount active">2</span>
                          <span className="stepcount">3</span>
                          <span className="stepcount">4</span>
                        </div>
                      </div>
                      <div className="profilescreen">
                        <h4 className="">
                          Please select upto 3 areas of interest
                        </h4>
                        <p className="mt-2">
                          We curate meaningful mentoring relationships and
                          connections based on your specified areas of interest.
                        </p>

                        <div className="businessboxouter">
                          {/* start main areas listing */}
                          {areas &&
                            areas.map((areaData, key) => {
                              let DOMAIN = mainArea.some(
                                (item) => item == areaData.id
                              );
                              return (
                                <div key={key}>
                                  <h5>
                                    <input
                                      type="checkbox"
                                      name="mainArea"
                                      value={areaData.id}
                                      id={`mainArea${areaData.id}`}
                                      // disabled="disabled"
                                      className={`form-check-input ${
                                        errors.mainArea ? "is-invalid" : ""
                                      }`}
                                      onClick={(e) =>
                                        setSelectedMainCategoryArray(
                                          e,
                                          areaData.id
                                        )
                                      }
                                      // defaultChecked={
                                      //   selectedMainAreaObj.includes(
                                      //     areaData.id.toString()
                                      //   )
                                      //     ? true
                                      //     : false
                                      // }
                                      // defaultChecked={mainArea.some((item) => {
                                      //   item == areaData.id.toString();
                                      // })}
                                      checked={DOMAIN == true ? 1 : 0}
                                      ref={register({
                                        required: true,
                                      })}
                                    />
                                    {errors.mainArea &&
                                      key === 0 &&
                                      errors.mainArea.type === "required" && (
                                        <label className="invalid-feedback text-left">
                                          Please choose at least one main area
                                        </label>
                                      )}
                                    <div className="selectboxouter">
                                      {areaData.name}
                                    </div>
                                  </h5>
                                  <ul className="bussinessSelect">
                                    {areaData &&
                                      areaData.subcategory.map((item, key) => {
                                        let SUB_AREA = selectedArea.some(
                                          (dta) => dta == item.id
                                        );
                                        // console.log("SUB_AREA", SUB_AREA);
                                        return (
                                          <li key={key}>
                                            <input
                                              type="checkbox"
                                              name="subarea"
                                              value={item.id}
                                              id={`subArea${item.id}`}
                                              // defaultChecked={
                                              //   selectedMainAreaObj.includes(
                                              //     areaData.id.toString()
                                              //   )
                                              //     ? true
                                              //     : false
                                              // }

                                              checked={SUB_AREA == true ? 1 : 0}
                                              className={`form-check-input mainArea${
                                                areaData.id
                                              } ${
                                                errors.subarea
                                                  ? "is-invalid"
                                                  : ""
                                              }`}
                                              onClick={(e) =>
                                                setSelectedMainAreaArray(
                                                  e,
                                                  areaData.id
                                                )
                                              }
                                              ref={register}
                                            />

                                            <div className="selectboxouter">
                                              {item.name}
                                            </div>
                                          </li>
                                        );
                                      })}
                                  </ul>
                                </div>
                              );
                            })}
                          {/* FInsih main areas listing */}
                        </div>
                      </div>
                      <div className="form-group mt-3 text-center">
                      <Button
                          className="btn loginsubmit nextbtn outline mr-2"
                          onClick={clearAll}
                        >
                          Clear All
                        </Button>
                        <Button
                          className="btn loginsubmit nextbtn outline mr-2"
                          onClick={(e) => {
                            e.preventDefault();
                            history.push("/mentee-signup-step-1");
                          }}
                        >
                          Previous
                        </Button>
                        <Button
                          type="submit"
                          className="btn loginsubmit nextbtn nextbtn ml-2 mr-2"
                        >
                          Save & Next
                        </Button>
                        {/* <Button
                          className="btn loginsubmit nextbtn outline mr-2 ml-2"
                          onClick={(e) => {
                            e.preventDefault();
                            window.location.href = "/mentee-signup-step-3";
                          }}
                        >
                          Next
                        </Button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
        </Container>
      </section>
    </div>
  );
};

export default StudentSignup2;
