import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap";
import { LOAD_DOMAINS_LIST, LOAD_AREAS_LIST } from "../../utils/apiPath";
import { agent } from "../../utils/agent";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import * as API from "../../utils/apiPath";
import { showToaster } from "../../utils/helpers";
import { LOAD_ORGANIZATION_LIST_SIGNUP } from "../../utils/apiPath";
const MentorSignUp2 = (props) => {
  const { title } = props;
  const { register, errors, handleSubmit } = useForm();
  const [objective, setObjective] = useState([]);
  const getObjectiveData = (page = 1) => {
    agent
      .get(LOAD_ORGANIZATION_LIST_SIGNUP + "/Mentor/objective", {})
      .then((res) => {
        setObjective(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getUserId = localStorage.getItem("userId");
  const [selectedObjective, setSelectedObjective] = useState([]);
  useEffect(() => {
    document.title = title;
    const getSelectedMainAreaData = (page = 1) => {
      agent
        .get(API.GETUSERDATA + "/" + getUserId)
        .then((res) => {
          if (res.data.data.domains) {
            setSelectedObjective(res.data.data.objective.split(","));
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };
    getSelectedMainAreaData();
    getObjectiveData();
  }, [title]);

  const history = useHistory();

  const setSelectedObjectiveArray = (e) => {
    const val = e.target.value;
    let cloneSelected1 = [...selectedObjective];

    if (selectedObjective.find((item) => item === val)) {
      cloneSelected1 = selectedObjective.filter((item) => item !== val);
    } else {
      cloneSelected1.push(val);
    }

    setSelectedObjective(cloneSelected1);
  };
  const onSubmit = (data) => {
    if(data?.objective.length>3){
      showToaster(
        "Please select only up to 3 objective for us to create a more meaningful connection for you."
      );
    }
    else{
      data.userId = localStorage.getItem("userId");
      data.stepNumber = 3;
      data.roleId = "Mentor";
      const response = agent.post(API.SAVEUSERDETAILS, data);
      history.push(`/mentor-signup-step-4`);
    }
  };

  const clearAll = () => {
    setSelectedObjective([])
  };
  return (
    <div>
      <section className="loginouter studentsteps">
        <Container>
          <Form onSubmit={handleSubmit(onSubmit)} className="mt-3">
            <Row className="align-items-center ">
              <Col md={12}>
                <div className="logininnerouter">
                  <div className="w-100">
                    <div className="loginlogo text-center mb-5 pb-4">
                      <Link to="/">
                        <img src="/assets/images/logo.svg" alt="logo" />
                      </Link>
                    </div>
                    <div className="loginform">
                      <h1>Tell us about yourself</h1>
                      <div className="steps_signup">
                        <div>
                          <span className="stepcount active">1</span>
                          <span className="stepcount active">2</span>
                          <span className="stepcount active">3</span>
                          <span className="stepcount">4</span>
                        </div>
                      </div>
                      <div className="profilescreen">
                        <h4 className="">
                          Your specified objectives will enable us to curate
                          meaningful connections. Please select upto 3
                          objectives to help us understand your expectations.
                        </h4>
                        <div className="businessboxouter">
                          <div className="mt-3">
                            <h5>Objective*</h5>
                            <ul className="bussinessSelect">
                              {objective &&
                                objective.map((item, key) => (
                                  <li key={key}>
                                    {key === 0 && (
                                      <input
                                        type="checkbox"
                                        name="objective"
                                        value={item.value}
                                        checked={
                                          selectedObjective.indexOf(
                                            item.value.toString()
                                          ) > -1
                                            ? true
                                            : false
                                        }
                                        onChange={(e) =>
                                          setSelectedObjectiveArray(e)
                                        }
                                        className={`form-check-input ${
                                          errors.objective ? "is-invalid" : ""
                                        }`}
                                        ref={register({
                                          required: true,
                                        })}
                                      />
                                    )}
                                    {errors.objective &&
                                      key === 0 &&
                                      errors.objective.type === "required" && (
                                        <label className="invalid-feedback text-left">
                                          Please choose at least one objective
                                        </label>
                                      )}

                                    {key !== 0 && (
                                      <input
                                        type="checkbox"
                                        name="objective"
                                        value={item.value}
                                        checked={
                                          selectedObjective.indexOf(
                                            item.value.toString()
                                          ) > -1
                                            ? true
                                            : false
                                        }
                                        onChange={(e) =>
                                          setSelectedObjectiveArray(e)
                                        }
                                        className={`form-check-input ${
                                          errors.objective ? "is-invalid" : ""
                                        }`}
                                        ref={register({
                                          required: true,
                                        })}
                                      />
                                    )}

                                    <div className="selectboxouter">
                                      {item.label}
                                    </div>
                                  </li>
                                ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="form-group mt-3 text-center">
                      <Button
                          className="btn loginsubmit nextbtn outline mr-2"
                          onClick={clearAll}
                        >
                          Clear All
                        </Button>
                        {" "}      
                        <Button
                          className="btn loginsubmit nextbtn outline mr-2"
                          onClick={(e) => {
                            e.preventDefault();
                            history.push("/mentor-signup-step-2");
                          }}
                        >
                          Previous
                        </Button>
                        <Button
                          type="submit"
                          className="btn loginsubmit nextbtn nextbtn ml-2 mr-2"
                        >
                          Save & Next
                        </Button>

                        {/* <Button
                          className="btn loginsubmit nextbtn outline ml-2"
                          onClick={(e) => {
                            e.preventDefault();
                            history.push("/mentor-signup-step-4");
                          }}
                        >
                          Next
                        </Button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Form>
        </Container>
      </section>
    </div>
  );
};

export default MentorSignUp2;
