import * as types from "./actionsTypes";
import { agent } from "../utils/agent";
import * as API from "../utils/apiPath";
import { showToaster } from "../utils/helpers";
import toggleNetworkRequestStatus from "./toggleNetworkRequestStatus";

export function loadDataSuccess(menteeRequestData) {
  return {
    type: types.LOADED_MENTEE_REQUEST_DATA,
    menteeRequestData,
  };
}

export function loadNotFoundDataSuccess(isData) {
  return { type: types.LOADED_DATA_SUCCESS, isData };
}

export function loadpaginationDataSuccess(pagination) {
  return { type: types.LOAD_PAGINATION_DATA_SUCCESS, pagination };
}

export const loadData = (request, status) => async (dispatch) => {
  try {
    if (request.short || request.keyword || request.page) {
      dispatch(toggleNetworkRequestStatus(true));
    }
    const response = await agent.get(API.LOAD_MENTEE_REQUEST + "/" + status, {
      params: {...request, type: "current"},
    });
    if (response.data.data.data.length <= 0) {
      dispatch(loadNotFoundDataSuccess(false));
    } else {
      dispatch(loadNotFoundDataSuccess(true));
    }
    if (response.data.data.data) {
      dispatch(loadDataSuccess(response.data.data.data));
      dispatch(loadpaginationDataSuccess(response.data.data.pagination));
      dispatch(toggleNetworkRequestStatus(false));
    }
  } catch (error) {
    showToaster(error.message);
    dispatch(toggleNetworkRequestStatus(false));
    dispatch(loadNotFoundDataSuccess(false));
  }
};

export const loadPendingRequestData = (request, status) => async (dispatch) => {
  try {
    if (request.short || request.keyword || request.page) {
      dispatch(toggleNetworkRequestStatus(true));
    }
    const response = await agent.get(
      API.LOAD_CUSTOMER + "/customerPendingRequest/" + status,
      {
        params: request,
      }
    );
    if (response.data.data.data.length <= 0) {
      dispatch(loadNotFoundDataSuccess(false));
    } else {
      dispatch(loadNotFoundDataSuccess(true));
    }
    if (response.data.data.data) {
      dispatch(loadDataSuccess(response.data.data.data));
      dispatch(loadpaginationDataSuccess(response.data.data.pagination));
      dispatch(toggleNetworkRequestStatus(false));
    }
  } catch (error) {
    showToaster(error.message);
    dispatch(toggleNetworkRequestStatus(false));
    dispatch(loadNotFoundDataSuccess(false));
  }
};

export const loadAcceptedMenteesData = (request) => async (dispatch) => {
  try {
    if (request.short || request.keyword || request.page) {
      dispatch(toggleNetworkRequestStatus(true));
    }
    const response = await agent.get(API.LOAD_ACCEPTED_MENTEES_DATA, {
      params: request,
    });
    if (response.data.data.data.length <= 0) {
      dispatch(loadNotFoundDataSuccess(false));
    } else {
      dispatch(loadNotFoundDataSuccess(true));
    }
    if (response.data.data.data) {
      dispatch(loadDataSuccess(response.data.data.data));
      dispatch(loadpaginationDataSuccess(response.data.data.pagination));
      dispatch(toggleNetworkRequestStatus(false));
    }
  } catch (error) {
    showToaster(error.message);
    dispatch(toggleNetworkRequestStatus(false));
    dispatch(loadNotFoundDataSuccess(false));
  }
};

export const myMentors = (request, userId) => async (dispatch) => {
  try {
    if (request.short || request.keyword || request.page) {
      dispatch(toggleNetworkRequestStatus(true));
    }
    const response = await agent.get(API.LOAD_MY_MENTORS + "/" + userId, {
      params: request,
    });
    if (response.data.data.data.length <= 0) {
      dispatch(loadNotFoundDataSuccess(false));
    } else {
      dispatch(loadNotFoundDataSuccess(true));
    }
    if (response.data.data.data) {
      dispatch(loadDataSuccess(response.data.data.data));
      dispatch(loadpaginationDataSuccess(response.data.data.pagination));
      dispatch(toggleNetworkRequestStatus(false));
    }
  } catch (error) {
    showToaster(error.message);
    dispatch(toggleNetworkRequestStatus(false));
    dispatch(loadNotFoundDataSuccess(false));
  }
};

export const pendingMentorsRequest = (request, userId) => async (dispatch) => {
  try {
    if (request.short || request.keyword || request.page) {
      dispatch(toggleNetworkRequestStatus(true));
    }
    const response = await agent.get(API.PENDING_MENTOR_REQUEST + "/" + userId, {
      params: request,
    });
    if (response.data.data.data.length <= 0) {
      dispatch(loadNotFoundDataSuccess(false));
    } else {
      dispatch(loadNotFoundDataSuccess(true));
    }
    if (response.data.data.data) {
      dispatch(loadDataSuccess(response.data.data.data));
      dispatch(loadpaginationDataSuccess(response.data.data.pagination));
      dispatch(toggleNetworkRequestStatus(false));
    }
  } catch (error) {
    showToaster(error.message);
    dispatch(toggleNetworkRequestStatus(false));
    dispatch(loadNotFoundDataSuccess(false));
  }
};

export const sendNotification = (request) => async (dispatch) => {
  dispatch(toggleNetworkRequestStatus(request));
};

export const updateUserReview = (params) => async (dispatch) => {
  try {
    const response = await agent.post(
      API.LOAD_CUSTOMER + "/updateReview",
      params
    );
    dispatch(loadDataSuccess(response.data.data.data));
    showToaster(response.data.message);
  } catch (error) {
    showToaster(error.message);
  }
};

export const updateReason = (params) => async (dispatch) => {
  dispatch(toggleNetworkRequestStatus(true));
  const response = await agent.post(
    API.LOAD_CUSTOMER + "/updateReason",
    params
  );
  if (response.status === 200) {
    dispatch(toggleNetworkRequestStatus(false));
    showToaster(response.data.message);
    dispatch({
      type: "REDIRECT",
      payload: "/my-mentors",
    });
  }
};
