// CMS Page Routes
import Home from "../Pages/Home";
import AboutUs from "../Pages/AboutUs";
import AboutUsApp from "../Pages/AboutUsApp";
import Organisation from "../Pages/Organisation";
import Partnerships from "../Pages/Partnerships";
import PartnershipsApp from "../Pages/PartnershipsApp";
import Faq from "../Pages/Faq";
import FaqApp from "../Pages/FaqApp";
import ContactUs from "../Pages/ContactUs";
import Mentors from "../Pages/Mentors";
import MentorDoid from "../Pages/MentorDoid";
import MentorIgnite from "../Pages/MentorIgnite";
import Cms from "../Pages/CMS";
import Program from "../Pages/Program";
//Auth Routes
import Login from "../Pages/Authentication/SignIn";
import ForgotPassword from "../Pages/Authentication/ForgotPassword";
import RestPassword from "../Pages/Authentication/RestPassword";
import Register from "../Pages/Authentication/Register";
import StudentSignUp from "../Pages/StudentSignUp/StudentSignup";
import StudentSignUp2 from "../Pages/StudentSignUp/StudentSignup2";
import StudentSignUp3 from "../Pages/StudentSignUp/StudentSignup3";
import StudentSignUp4 from "../Pages/StudentSignUp/StudentSignup4";
// import StudentSignUp11 from "../Pages/StudentSignUp/StudentSignup11";
import MentorSignUp from "../Pages/MentorSignUp/MentorSignUp";
import MentorSignUp2 from "../Pages/MentorSignUp/MentorSignUp2";
import MentorSignUp3 from "../Pages/MentorSignUp/MentorSignUp3";
import MentorSignUp4 from "../Pages/MentorSignUp/MentorSignUp4";

// After login
import MentorListing from "../Pages/MentorListing";
import MenteeListing from "../Pages/MenteeListing";
import Dashboard from "../Pages/Dashboard";
import CurrentMentees from "../Pages/MentorDashboard1";
import SuggestedMentor from "../Pages/MenteeDashboard";
import UpdateProfile from "../Pages/UpdateProfile/UpdateProfile1";
import UpdateProfile2 from "../Pages/UpdateProfile/UpdateProfile2";
import UpdateProfile3 from "../Pages/UpdateProfile/UpdateProfile3";
import UpdateProfile4 from "../Pages/UpdateProfile/UpdateProfile4";
import Password from "../Pages/Password";
import MenteeRequest from "../Pages/MenteesRequest";
import MyMentors from "../Pages/MyMentors";
import PendingMenterRequest from "../Pages/PendingMenterRequest";
import MenteesLog from "../Pages/MenteesLog";
import Availability from "../Pages/Availability";
import Chat from "../Pages/Chat";
import Resourcehub from "../Pages/Resourcehub/ResourcesHub";
import PostResourcehub from "../Pages/Resourcehub/PostResourcesHub";
import ResourcesDetail from "../Pages/ResourcesDetail";
import DeleteProfile from "../Pages/DeleteProfile/DeleteProfile";
import CommunityDetail from "../Pages/CommunityDetail";
import MyDiagnosticToolkit from "../Pages/MyDiagnosticToolkit";
import Refer from "../Pages/Refer";
import Forum from "../Pages/Forum";
import ForumTest from "../Pages/Forum/forumtest";
import CKEditorFileUpload from "../Pages/CKEditorFileUpload";

//Events
import Event from "../Pages/Event";
import EventDetail from "../Pages/Event/detail";
import Newsletter from "../Pages/Resourcehub/Newsletter";
import NewsletterApp from "../Pages/Resourcehub/NewsletterApp";
import Videos from "../Pages/Resourcehub/Video";
import PreRegistrationFrom from "../Pages/PreRegistrationFrom";
import Certificate from "../Pages/Certificate";
import ScheduleListing from "../Pages/ScheduleListing";
import ScheduleListingCalendar from "../Pages/ScheduleListingCalendar"
import MentorSCS from "../Pages/MentorSCS";

export const RoutesPage = [
  { path: "/", name: "Home", component: Home, title: "vLookUp" },
  {
    path: "/mentors",
    name: "Mentors",
    component: Mentors,
    title: "Mentors",
  },
  {
    path: "/mahadewi-mentoring",
    name: "Mentors",
    component: MentorDoid,
    title: "Mentors",
  },
  {
    path: "/scs-wit-mentoring",
    name: "Mentors",
    component: MentorSCS,
    title: "Mentors",
  },
  {
    path: "/ignite-women-mentoring",
    name: "Mentors",
    component: MentorIgnite,
    title: "Mentors",
  },
  {
    path: "/aboutus",
    name: "aboutus",
    component: AboutUs,
    title: "vLookUp | Helping women grow and lea",
  },
  {
    path: "/organisations",
    name: "organisations",
    component: Organisation,
    title: "Partners and Organisations",
  },
  {
    path: "/faq",
    name: "Faq",
    component: Faq,
    title: "FAQ's",
  },

  {
    path: "/partnerships",
    name: "partnerships",
    component: Partnerships,
    title: "Partnerships",
  },
  {
    path: "/contact-us",
    name: "Contact Us",
    component: ContactUs,
    title: "Contact Us",
  },
  {
    path: "/resource-hub/:slug",
    name: "Resourcehub",
    component: Resourcehub,
    title: "Resourcehub",
  },
  {
    path: "/resource-hub",
    name: "Resourcehub",
    component: Resourcehub,
    title: "Resourcehub",
  },
  {
    path: "/newsletter",
    name: "Newsletter",
    component: Newsletter,
    title: "Newsletter",
  },
  {
    path: "/videos",
    name: "Video",
    component: Videos,
    title: "Video",
  },
  {
    path: "/post-resource-hub",
    name: "PostResourcehub",
    component: PostResourcehub,
    title: "PostResourcehub",
  },
  {
    path: "/resources-details/:slug",
    name: "ResourcesDetail",
    component: ResourcesDetail,
    title: "ResourcesDetail",
  },
  {
    path: "/resources-details",
    name: "Resourcehub",
    component: Resourcehub,
    title: "Resourcehub",
  },
  {
    path: "/community-details/:slug",
    name: "CommunityDetail",
    component: CommunityDetail,
    title: "CommunityDetail",
  },
  {
    path: "/page/:slug",
    name: "PrivacyPolicy",
    component: Cms,
    title: "Privacy Policy",
  },
  {
    path: "/uploader/upload",
    name: "FileUpload",
    component: CKEditorFileUpload,
    title: "File Upload",
  },
  {
    path: "/event/:type",
    name: "Event",
    component: Event,
    title: "Event",
  },
  {
    path: "/event-detail/:slug",
    name: "EventDetail",
    component: EventDetail,
    title: "Event Detail",
  },
];

export const RoutesAuth = [
  {
    path: "/login",
    name: "Login",
    component: Login,
    title: "Login",
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    title: "Register",
  },
  {
    path: "/aboutusapp",
    name: "AboutUsApp",
    component: AboutUsApp,
    title: "About Us",
  },
  {
    path: "/faqapp",
    name: "faqapp",
    component: FaqApp,
    title: "FAQ's",
  },
  {
    path: "/partnershipsapp",
    name: "partnershipsapp",
    component: PartnershipsApp,
    title: "Partnerships",
  },
  // {
  //   path: "/program",
  //   name: "Program",
  //   component: Program,
  //   title: "Program",
  // },

  {
    path: "/newsletterapp/:slug",
    name: "NewsletterApp",
    component: NewsletterApp,
    title: "Newsletter",
  },
  {
    path: "/mentee-signup-step-1",
    name: "StudentSignUp",
    component: StudentSignUp,
    title: "StudentSignUp",
  },
  // {
  //   path: "/mentee-signup-step-11",
  //   name: "StudentSignUp 1",
  //   component: StudentSignUp11,
  //   title: "StudentSignUp 1",
  // },
  {
    path: "/mentee-signup-step-2",
    name: "StudentSignUp step 2",
    component: StudentSignUp2,
    title: "StudentSignUp step 2",
  },
  {
    path: "/mentee-signup-step-3",
    name: "StudentSignUp step 3",
    component: StudentSignUp3,
    title: "StudentSignUp step 3",
  },
  {
    path: "/mentee-signup-step-4",
    name: "StudentSignUp4",
    component: StudentSignUp4,
    title: "StudentSignUp4",
  },

  {
    path: "/mentor-signup-step-1",
    name: "MentorSignUp",
    component: MentorSignUp,
    title: "MentorSignUp",
  },
  {
    path: "/mentor-signup-step-2",
    name: "MentorSignUp step 2",
    component: MentorSignUp2,
    title: "MentorSignUp step 2",
  },
  {
    path: "/mentor-signup-step-3",
    name: "MentorSignUp step 3",
    component: MentorSignUp4,
    title: "MentorSignUp step 3",
  },
  {
    path: "/mentor-signup-step-4",
    name: "MentorSignUp4",
    component: MentorSignUp3,
    title: "MentorSignUp4",
  },

  {
    path: "/forgotPassword",
    name: "ForgotPassword",
    component: ForgotPassword,
    title: "Forgot Password",
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
    title: "Forgot Password",
  },
  {
    path: "/reset-password",
    name: "Rest Password",
    component: RestPassword,
    title: "Rest Password",
  },
  {
    path: "/pre-registration-form",
    name: "Pre Registration From",
    component: PreRegistrationFrom,
    title: "Pre Registration From",
  },
];

export const RoutesUser = [
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    title: "Dashboard",
  },
  {
    path: "/current-mentees",
    name: "CurrentMentees",
    component: CurrentMentees,
    title: "Current Mentees",
  },
  // {
  //   path: "/schedule-meeting",
  //   name: "ScheduleListing",
  //   component: ScheduleListing,
  //   title: "Schedule Listing",
  // },
  {
    path: "/schedule-meeting-calendar",
    name: "ScheduleListingCalendar",
    component: ScheduleListingCalendar,
    title: "Calendar/Meetings",
  },
  {
    path: "/suggested-mentors",
    name: "SuggestedMentor",
    component: SuggestedMentor,
    title: "Suggested Mentors",
  },
  {
    path: "/dashboard-mentors",
    name: "MentorsListing",
    component: MentorListing,
    title: "MentorsListing",
  },
  {
    path: "/mentees-listing",
    name: "MenteeListing",
    component: MenteeListing,
    title: "MenteeListing",
  },
  {
    path: "/manage-profile",
    name: "ManageProfile",
    component: UpdateProfile,
    title: "ManageProfile",
  },
  {
    path: "/manage-profile-step2",
    name: "ManageProfile-step2",
    component: UpdateProfile2,
    title: "ManageProfile-step2",
  },
  {
    path: "/manage-profile-step3",
    name: "ManageProfile-step3",
    component: UpdateProfile3,
    title: "ManageProfile-step3",
  },
  {
    path: "/manage-profile-step4",
    name: "ManageProfile-step4",
    component: UpdateProfile4,
    title: "ManageProfile-step4",
  },
  {
    path: "/change-password",
    name: "change-password",
    component: Password,
    title: "change-password",
  },
  {
    path: "/mantees-request",
    name: "mantees-request",
    component: MenteeRequest,
    title: "mantees-request",
  },
  {
    path: "/my-mentors",
    name: "my-mentors",
    component: MyMentors,
    title: "my-mentors",
  },
  {
    path: "/pending-mentors",
    name: "pending-mentors",
    component: PendingMenterRequest,
    title: "pending-mentors",
  },
  {
    path: "/mentees-logs",
    name: "mentees-logs",
    component: MenteesLog,
    title: "mentees-logs",
  },
  {
    path: "/my-diagnostic-toolkit",
    name: "my-diagnostic-toolkit",
    component: MyDiagnosticToolkit,
    title: "My Diagnostic Toolkit",
  },
  {
    path: "/certificate",
    name: "certificate",
    component: Certificate,
    title: "Certificate",
  },
  {
    path: "/my-diagnostic-toolkit",
    name: "my-diagnostic-toolkit",
    component: MyDiagnosticToolkit,
    title: "My Diagnostic Toolkit",
  },
  {
    path: "/availability",
    name: "Availability",
    component: Availability,
    title: "Availability",
  },
  {
    path: "/chat",
    name: "Chat",
    component: Chat,
    title: "Chat",
  },
  {
    path: "/delete-my-profile",
    name: "DeleteProfile",
    component: DeleteProfile,
    title: "Delete Profile",
  },
  {
    path: "/refer",
    name: "Refer",
    component: Refer,
    title: "Refer vLookUp",
  },
  {
    path: "/forum",
    name: "Forum",
    component: Forum,
    title: "vLookUp Forum",
  },
  {
    path: "/forumtest",
    name: "Forum",
    component: ForumTest,
    title: "vLookUp Forum",
  },
];
