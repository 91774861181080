import React, { useEffect, Fragment } from "react";
import { Link, useHistory, NavLink } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as userAction from "../../actions/userActions";
import * as baseActions from "../../actions/baseActions";
import placeholder from "../../assets/images/userprofile.png";
import dash_menu7 from "../../assets/images/dash_menu7.png";
import pencil_icon from "../../assets/images/pencil_icon.png"



const Index = (props) => {
  const { title, actions, updateProfileData, userInfo } = props;
  var roleId;
  if (userInfo.role_id === 'Mentees') {
    roleId = 'Mentee';
  }
  else {
    roleId = userInfo.role_id;
  }

  useEffect(() => {
    document.title = title;
    actions.getUserProfile(userInfo.id);

  }, [title, actions, userInfo]);

  let history = useHistory();

  function handleLogOut() {
    localStorage.setItem("authToken", "");
    // localStorage.clear();
    let params = {};
    actions.userLogout(params);
    localStorage.removeItem("profileImage");
    localStorage.removeItem("profile_img");
    history.push("/login"); // whichever component you want it to route to
  }
  function SidebarRemoveClass() {
    document.getElementById("OnscrollBody").classList.remove("show_sidebar");
    document.getElementById("OnscrollBody").classList.remove("chatbodyclass");
  }
  const profileImage = localStorage.getItem('profileImage')
  return (
    <div className="dashboard_sidebar">
      <div className="dashboard_profile text-center mb-2 mb-md-3 mb-lg-4">
        <img src={updateProfileData.image || profileImage || placeholder} alt="dashboardImage" />
        <h5>
          {updateProfileData.first_name} {updateProfileData.last_name}
        </h5>
      </div>
      <div className="dashboard_menu">
        <ul>
          <li>
            <NavLink to="/manage-profile" activeClassName="active" onClick={SidebarRemoveClass}>
              <span>
                <img
                  src={
                    process.env.REACT_APP_STATIC_IMAGE_PATH + "dash_menu2.png"
                  }
                  alt="Manage My Profile"
                />
              </span>
              Manage My Profile
            </NavLink>
          </li>
          {updateProfileData.role_id === "Mentees" && (
            <Fragment>
              <li className="active">
                <NavLink to="/suggested-mentors" activeClassName="active" onClick={SidebarRemoveClass}>
                  <span>
                    <img
                      src={
                        process.env.REACT_APP_STATIC_IMAGE_PATH + "dash_menu1.png"
                      }
                      alt="Suggested Mentors"
                    />
                  </span>
                  Suggested Mentors
                </NavLink>
              </li>
              <li>
                <NavLink to="/my-mentors" activeClassName="active" onClick={SidebarRemoveClass}>
                  <span>
                    <img
                      src={
                        process.env.REACT_APP_STATIC_IMAGE_PATH + "mentor_log_icon.png"
                      }
                      alt="My Mentor Log"
                    />
                  </span>
                  My Mentor Log
                </NavLink>
              </li>
              <li>
                <NavLink to="/pending-mentors" activeClassName="active" onClick={SidebarRemoveClass}>
                  <span>
                    <img
                      src={
                        process.env.REACT_APP_STATIC_IMAGE_PATH + "mentor_log_icon.png"
                      }
                      alt="Pending Mentor Requests"
                    />
                  </span>
                  Pending Mentor Requests
                </NavLink>
              </li>
              <li>
                <NavLink to="/my-diagnostic-toolkit" activeClassName="active" onClick={SidebarRemoveClass}>
                  <span>
                    <img
                      src={
                        pencil_icon
                      }
                      alt=" My Diagnostic Toolkit"
                    />
                  </span>
                  My Diagnostic Toolkit
                </NavLink>
              </li>

            </Fragment>
          )}
          <li>
            <NavLink to="/certificate" activeClassName="active" onClick={SidebarRemoveClass}>
              <span style={{ width: 27 }}>
                <img
                  src={
                    dash_menu7
                  }
                  alt="Certificate"
                />
              </span>
              Certificate
            </NavLink>
          </li>

          {/* <li>
            <NavLink to="/schedule-meeting" activeClassName="active" onClick={SidebarRemoveClass}>
              <span style={{ width: 27 }}>
                <img
                  src={
                    dash_menu7
                  }
                  alt="ScheduleMeeting"
                />
              </span>
              Schedule Meeting
            </NavLink>
          </li> */}

          <li>
            <NavLink to="/schedule-meeting-calendar" activeClassName="active" onClick={SidebarRemoveClass}>
              <span style={{ width: 27 }}>
                <img
                  src={
                    process.env.REACT_APP_STATIC_IMAGE_PATH + "dash_menu3.png"
                  }
                  alt="ScheduleMeeting"
                />
              </span>
              Calendar/Meetings
            </NavLink>
          </li>

          {updateProfileData.role_id === "Mentor" && (
            <Fragment>
              <li className="active">
                <NavLink to="/current-mentees" activeClassName="active" onClick={SidebarRemoveClass}>
                  <span>
                    <img
                      src={
                        process.env.REACT_APP_STATIC_IMAGE_PATH + "dash_menu1.png"
                      }
                      alt="Current Mentees"
                    />
                  </span>
                  Current Mentees
                </NavLink>
              </li>
              <li>
                <NavLink to="/mantees-request" activeClassName="active" onClick={SidebarRemoveClass}>
                  <span>
                    <img
                      src={
                        process.env.REACT_APP_STATIC_IMAGE_PATH + "dash_menu2.png"
                      }
                      alt="Pending Requests"
                    />
                  </span>
                  Pending Requests
                </NavLink>
              </li>
              <li>
                <NavLink to="/mentees-logs" activeClassName="active" onClick={SidebarRemoveClass}>
                  <span>
                    <img
                      src={
                        process.env.REACT_APP_STATIC_IMAGE_PATH + "dash_menu2.png"
                      }
                      alt=" Past Mentees"
                    />
                  </span>
                  Past Mentees
                </NavLink>
              </li>
            </Fragment>
          )}
          {/* <li>
            <NavLink to="/forum" activeClassName="active" onClick={SidebarRemoveClass}>
              <span>
                <img
                  src={
                    process.env.REACT_APP_STATIC_IMAGE_PATH + "forum_menu.png"
                  }
                  alt="asdasd"
                />
              </span>
              Forum
            </NavLink>
          </li> */}
          <li>
            <NavLink to="/chat" activeClassName="active" onClick={SidebarRemoveClass}>
              <span>
                <img
                  src={
                    process.env.REACT_APP_STATIC_IMAGE_PATH + "messages_icon.png"
                  }
                  alt="Messages"
                />
              </span>
              Messages
            </NavLink>
          </li>
          <li>
            <NavLink to="/refer" activeClassName="active" onClick={SidebarRemoveClass}>
              <span>
                <img
                  src={
                    process.env.REACT_APP_STATIC_IMAGE_PATH + "refer_icon.png"
                  }
                  alt={"Invite a " + roleId}
                />
              </span>
              Invite a {roleId}
            </NavLink>
          </li>

          <li>
            <NavLink to="/delete-my-profile" activeClassName="active" onClick={SidebarRemoveClass}>
              <span>
                <img
                  src={
                    process.env.REACT_APP_STATIC_IMAGE_PATH + "delete_profile_icon.png"
                  }
                  alt="Delete My Profile"
                />
              </span>
              Delete My Profile
            </NavLink>
          </li>
          <li>
            <Link to="/" onClick={handleLogOut}>
              <span>
                <img
                  src={
                    process.env.REACT_APP_STATIC_IMAGE_PATH + "dash_menu6.png"
                  }
                  alt="logout"
                />
              </span>
              Logout
            </Link>
          </li>

        </ul>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    userInfo: state.userInfo,
    updateProfileData: state.updateProfileData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      Object.assign(userAction, baseActions),
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);
