import React from 'react'
import { Link } from 'react-router-dom'
import "../../assets/css/developer.css";

function NotFound({ message, RouteNotFound }) {
 return (<>
      <title>404 - Page Not Found</title>
    <section className="page_404">
      <div className="container">
        <div className="row">
          <div className="col-sm-12  justify-content-center">
            <div className="col-sm-12 col-sm-offset-1  text-center">
              <div className="four_zero_four_bg">
                <h1 className="text-center "></h1>
              </div>
              <div className="contant_box_404">
                <h3 className="h2">
                  Look like you're lost
                </h3>
                <p>The page you are looking for not available!</p>
                <p>
                  If you feel you need to get in touch, please <Link to="/contact-us" style={{ color: '#337ab7', textDecoration: 'none' }}>Contact Us</Link></p>
                <Link to='/' className="btn btn_join mr-2" style={{ textDecoration: 'none', }}>Go to Home</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  </>)

 
}

export default NotFound